<template>
    <footer class="lead-footer lead-main-footer lead-footer-dark" style="background-color: rgb(18, 17, 24);">
        <div class="container" style="margin-bottom: -100px;">
            <div class="row">
                <div class="col-lg-5 col-md-6">
                    <div class="footer-brand-wrapper"><img src="https://test3.dedalus.cc/assets/img/dedalus.png" alt="logo white" width="160"></div>
                    <!--
                    <nav class="lead-social-menu">
                        <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></a>
                        <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a>
                        <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a>
                        <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a>
                    </nav>
                    -->
                </div>
                <div class="col-lg-5 col-md-6 ml-md-auto">
                    <div class="row">
                        <!--
                        <div class="col">
                            <nav class="nav footer-nav nav-vertical my-4 my-md-0">
                                
                                <a href="https://edeline.ru/nft-demo/wallet.html" class="nav-link">Wallet</a>
                            </nav>
                        </div>
                        -->
                        <div class="col">
                            <nav class="nav footer-nav nav-vertical my-4 my-md-0">
                                <router-link  class="nav-link" :to="{ name: 'Collections' }">
                                    Collections
                                </router-link>

                                <router-link  class="nav-link" :to="{ name: 'Authors' }">
                                    Authors
                                </router-link>

                                <router-link  class="nav-link" :to="{ name: 'Abouts' }">
                                    About
                                </router-link>

                                <a href="/terms_and_conditions" target="_blank" class="nav-link">Terms and conditions</a>
<!--
                                <router-link  class="nav-link" :to="{ name: 'Terms' }">
                                    Terms and conditions
                                </router-link>
-->
                                
                            </nav>
                        </div>

                        <div class="col">
                            <router-link  class="nav-link nav footer-nav nav-vertical my-4 my-md-0" :to="{ name: 'Contacts' }">
                                    Contacts
                            
                            </router-link>

                            <!--
                            <nav class="nav footer-nav nav-vertical my-4 my-md-0">
                                <a href="https://www.dedalus.cc" target="_blank" class="nav-link">Community</a>
                            </nav>
                            -->
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <p class="footer-text" style="text-align: center;">©2022 Dedalus . All rights reserved. </p>
        </div>
    </footer>
</template>