<template>
  <div class="pages-header"> 
    
  </div>
</template>




<style lang="scss" scoped>

    .pages-header {
        height: 49vh;
    }
</style>