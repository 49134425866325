import { getCurrentInstance, inject } from "vue";
export function injectStrict(key, fallback) {
    const resolved = inject(key, fallback);
    if (typeof resolved === undefined) {
        throw new Error(`Could not resolve ${key.description}`);
    }
    return resolved;
}
export function injectStrictWithSelf(key) {
    const vm = getCurrentInstance();
    return vm?.provides[key] && injectStrict(key);
}
