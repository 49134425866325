import { ethers, providers } from "ethers";
import { onMounted, provide, reactive } from "vue";
import { injectStrictWithSelf } from "../../utils/injectStrict";
import { abiMarket, MARKET_ADDRESS_CONTRACT } from "../../abis/MARKET";
import { NFT_ADDRESS_CONTRACT } from "../../abis/NFT";
import { getAssetByID, getAuthorCollectionsByAuthorID, getAuthors, getCollectionByID, getCollections, getNewAssets, getPopularCollections, getRandomAssets, getTakeOurProposals, requestAboutInfo, sendContactMessage } from "./api";
import { getAssetsByOwnerMinter, getNftByOwnerMarket } from "./api-graph";
import Web3Modal, { getInjectedProvider } from "web3modal";
import Portis from "@portis/web3";
import { abiUSDT, USDT_ADDRESS_CONTRACT } from "../../abis/USDT";
export class StateApp {
    message = "";
    loading = false;
    authors = [];
    collections = [];
    collection;
    author;
    asset;
    assets = [];
    user;
    caruselAssets = [];
    getMessage() {
        return this.message;
    }
    clearMessage() {
        this.message = "";
    }
    async loadCollection(id) {
        this.collection = undefined;
        await getCollectionByID(id).then(res => {
            this.collection = res;
        });
    }
    isOwner() {
        return this.asset?.owner?.toLowerCase() == MARKET_ADDRESS_CONTRACT.toLowerCase();
    }
    initWeb3Modal() {
        const providerOptions = {
            // Example with injected providers
            injected: {
                display: {
                    // logo: "data:image/gif;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaW5ZTWluIG1lZXQiIHZpZXdCb3g9IjAgMCAzNTAgMzUwIj4NCiAgICA8c3R5bGU+LmJhc2UgeyBmaWxsOiB3aGl0ZTsgZm9udC1mYW1pbHk6IHNlcmlmOyBmb250LXNpemU6IDE0cHg7IH08L3N0eWxlPg0KICAgIDxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9ImJsYWNrIiAvPg0KICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBjbGFzcz0iYmFzZSIgZG9taW5hbnQtYmFzZWxpbmU9Im1pZGRsZSIgdGV4dC1hbmNob3I9Im1pZGRsZSI+RXBpY0xvcmRIYW1idXJnZXI8L3RleHQ+DQo8L3N2Zz4=",
                    name: "Metamask",
                    description: "Connect with the provider in your Browser"
                },
                package: null
            },
            // Example with WalletConnect provider
            portis: {
                package: Portis,
                options: {
                    id: "PORTIS_ID" // required
                }
            }
        };
        const web3ModalRef = new Web3Modal({
            cacheProvider: true,
            network: "chapel",
            providerOptions: providerOptions,
            theme: {
                background: "rgb(39, 49, 56)",
                main: "rgb(199, 199, 199)",
                secondary: "rgb(136, 136, 136)",
                border: "rgba(195, 195, 195, 0.14)",
                hover: "rgb(16, 26, 32)"
            }
        });
        //web3ModalRef.clearCachedProvider();
        this.web3ModalRef = web3ModalRef;
    }
    getCollection() {
        return this.collection;
    }
    getCollections() {
        return this.collections ?? [];
    }
    isLoading() {
        return this.loading;
    }
    getUser() {
        return this.user;
    }
    getOwnerAssets() {
        const ids = this.assets.map(item => item.metadata?.mint ?? "");
        this.loadAssetsStatusMarketGraph(ids.filter(item => item != "")).then(res => {
            console.log(res);
            res.artes.forEach((item) => {
                const owner = item.owner.id;
                const author = item.author.id;
                const index = this.assets.findIndex(asset => asset.metadata?.mint == item.id);
                if (index > -1) {
                    this.assets[index].owner = owner;
                    this.assets[index].author = author;
                }
            });
        });
    }
    web3ModalRef;
    provider;
    async getSignOrProvider(needSigner = false) {
        if (this.web3ModalRef == null) {
            this.initWeb3Modal();
            // await web3ModalRef.connect();
        }
        if (this.web3ModalRef && this.web3ModalRef.cachedProvider == "") {
            this.provider = await this.web3ModalRef.connect().catch(err => {
                debugger;
                console.log(err);
            });
        }
        const web3Provider = new providers.Web3Provider(this.provider);
        const { chainId } = await web3Provider.getNetwork();
        if (chainId != 97) { //if(chainId!=80001){
            alert("use chapel network");
            throw new Error("Change network chapel");
        }
        if (needSigner) {
            const signer = web3Provider.getSigner();
            return signer;
        }
        return this.provider;
    }
    //signer:ethers.providers.Provider | ethers.Signer | undefined;
    async logIn() {
        //const user= this.getUser();
        const injectsProviders = getInjectedProvider();
        if (!injectsProviders) {
            alert("Metamask plugin not found on this browser.");
            return;
        }
        const signer = await this.getSignOrProvider(true);
        //await provider.send("eth_requestAccounts", []);
        if (signer instanceof ethers.Signer) {
            await signer.getAddress().then((res) => {
                this.user = res;
                //console.log(res)
                return res;
            });
        }
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        console.log(chainId);
        window.ethereum.on('connect', (connectInfo) => {
            console.log('connect event', connectInfo);
        });
        return await window.ethereum.request({ method: 'eth_accounts' }).then((res) => {
            console.log('login', res);
            this.user = res[0];
            this.isApproved();
        }).
            catch((e) => {
            console.log(e);
        });
    }
    logout() {
        if (this.web3ModalRef) {
            this.web3ModalRef = undefined;
        }
        /*if(window.ethereum){
            window.ethereum.on('accountsChanged',null);
        }*/
        if (this.asset)
            this.asset.isApproved = false;
        this.user = undefined;
    }
    handlerNewAccount(e) {
        if (e && e.length > 0) {
            this.user = e[0];
            this.isApproved();
        }
        else {
            this.user = undefined;
            this.clearWeb3Modal();
        }
    }
    async loadAuthors() {
        await getAuthors().then(res => {
            this.authors = res;
        });
    }
    async decimals() {
        const signer = await this.getSignOrProvider(true); //provider.getSigner();
        const usdtContractSigner = new ethers.Contract(USDT_ADDRESS_CONTRACT, abiUSDT, signer);
        return await usdtContractSigner.decimals();
        //return decimal;
    }
    getOwnerAssetsAuthor() {
        if (this.author == null)
            return;
        const ids = this.author?.files.map(item => item.metadata?.mint ?? "");
        this.loadAssetsStatusMarketGraph(ids.filter(item => item != "")).then(res => {
            console.log(res);
            res.artes.forEach((item) => {
                const owner = item.owner.id;
                const author = item.author.id;
                if (this.author != null) {
                    const index = this.author.files.findIndex(asset => asset.metadata?.mint == item.id);
                    if (index > -1) {
                        this.author.files[index].owner = owner;
                        this.author.files[index].author = author;
                    }
                }
            });
        });
    }
    async loadAuthorCollectionsByAuthorId(authorId) {
        await getAuthorCollectionsByAuthorID(authorId).then(res => {
            this.author = res;
        });
    }
    loadSingleAssetStatusMarketGraph(mint) {
        getNftByOwnerMarket(MARKET_ADDRESS_CONTRACT.toLocaleLowerCase(), mint).then(res => {
            if (res && res.artes.length > 0) {
                const owner = res.artes[0].owner.id;
                const author = res.artes[0].author.id;
                this.asset = {
                    ...this.asset ?? { id: undefined, smartContract: undefined, metadata: undefined, url: undefined, prezzo: "0.0", price_commission: "0.0" },
                    owner,
                    isApproved: false,
                    author
                };
            }
        });
    }
    loadAssetsStatusMarketGraph(mintsId) {
        console.log(mintsId);
        return getAssetsByOwnerMinter(MARKET_ADDRESS_CONTRACT.toLocaleLowerCase(), mintsId).then(res => {
            return res;
            //if(res && res.artes.length>0){
            //}
        });
    }
    getOwnerAssetsCollection() {
        if (this.collection == null)
            return;
        const ids = this.collection?.file.map(item => item.metadata?.mint ?? "");
        this.loadAssetsStatusMarketGraph(ids.filter(item => item != "")).then(res => {
            console.log(res);
            res.artes.forEach((item) => {
                const owner = item.owner.id;
                const author = item.author.id;
                if (this.collection != null) {
                    const index = this.collection.file.findIndex(asset => asset.metadata?.mint == item.id);
                    if (index > -1) {
                        this.collection.file[index].owner = owner;
                        this.collection.file[index].author = author;
                    }
                }
            });
        });
    }
    async isApproved() {
        if (!this.asset) {
            return;
            //if(this.asset.isApproved)return;
        }
        const signer = await this.getSignOrProvider(true); //provider.getSigner();
        const usdtContractSigner = new ethers.Contract(USDT_ADDRESS_CONTRACT, abiUSDT, signer);
        const decimal = await usdtContractSigner.decimals();
        let allowance = await usdtContractSigner.allowance(this.user, MARKET_ADDRESS_CONTRACT);
        let allowanceToNumber = allowance.toBigInt();
        //allowance = ethers.utils.formatUnits(allowance,decimal);
        console.log(allowance);
        if (this.asset) {
            const prezzoAsset = ethers.utils.parseUnits(this.asset.price_commission.toString(), decimal).toBigInt();
            if (allowanceToNumber >= prezzoAsset) {
                this.asset.isApproved = true;
            }
        }
    }
    /**aprovamos la transaction prima della compra */
    async approve() {
        const address_market = MARKET_ADDRESS_CONTRACT.toLowerCase();
        if (this.asset == undefined)
            return;
        if (this.asset.owner != address_market)
            return;
        const signer = await this.getSignOrProvider(true); //provider.getSigner();
        const usdtContractSigner = new ethers.Contract(USDT_ADDRESS_CONTRACT, abiUSDT, signer);
        const decimal = await usdtContractSigner.decimals();
        let allowance = await usdtContractSigner.allowance(this.user, MARKET_ADDRESS_CONTRACT);
        let allowanceToNumber = allowance.toBigInt();
        const prezzoToNumber = ethers.utils.parseUnits(this.asset.price_commission.toString(), decimal);
        const prezzoHuman = prezzoToNumber.toBigInt();
        if (allowanceToNumber >= prezzoHuman) {
            return;
        }
        //allowance= ethers.utils.formatUnits(allowance,decimal);
        /*usdtContractSigner.on('Approval', (owner, spender, amount: ethers.BigNumber) => {

            //this.loading=false;
            console.log(owner,spender,amount)
            alert("La approvazione e finalizato!")
            if(this.asset)
                this.asset.isApproved=true;
            
            
        })*/
        //console.log(allowance);
        const balanceUsdc = await usdtContractSigner.balanceOf(this.user);
        const balanceToNumber = balanceUsdc.toBigInt();
        if (prezzoToNumber.toBigInt() > balanceToNumber) {
            alert("insufficient balance");
            return;
        }
        const txApprove = await usdtContractSigner.approve(MARKET_ADDRESS_CONTRACT, prezzoToNumber);
        await txApprove.wait().then((res) => {
            if (this.asset)
                this.asset.isApproved = true;
            alert("La approvazione e finalizato!");
        }).finally(() => this.loading = false);
        //txMintNft= ethers.utils.formatUnits(txMintNft,decimal);
        //console.log(txMintNft);
    }
    async loadAssetByMintContract(mint) {
        this.loading = true;
        this.asset = undefined;
        getAssetByID(NFT_ADDRESS_CONTRACT, mint).then(res => {
            this.asset = {
                ...this.asset,
                ...res
            };
        }).finally(() => this.loading = false);
    }
    loadCollections() {
        getCollections().then(res => {
            this.collections = res;
        });
    }
    paginationAssets;
    latestWorksAssets;
    /**
     * Latest works
     */
    async loadNewAssets() {
        this.loading = true;
        const page = this.latestWorksAssets != undefined ? this.latestWorksAssets.pagination.next_page : 0;
        if (this.latestWorksAssets) {
            if (this.latestWorksAssets.pagination.next_page == null)
                return;
        }
        await getNewAssets(page).then(async (res) => {
            if (page == 0)
                this.latestWorksAssets = res;
            if (page != 0 && this.latestWorksAssets && res.assets.length > 0)
                this.latestWorksAssets = {
                    assets: [
                        ...this.latestWorksAssets.assets,
                        ...res.assets
                    ],
                    pagination: res.pagination
                };
        })
            .finally(() => this.loading = false);
    }
    getLatestWorksAssets() {
        return this.latestWorksAssets;
    }
    /**
     * contenuto della api popular collections
     */
    popularCollections;
    async loadPopularCollections() {
        this.loading = true;
        const page = this.popularCollections != undefined ? this.popularCollections.pagination?.next_page : 0;
        await getPopularCollections(page).then(async (res) => {
            if (page == 0)
                this.popularCollections = res;
            if (this.popularCollections && page > this.popularCollections.pagination.next_page)
                this.popularCollections = {
                    collections: [
                        ...this.popularCollections.collections,
                        ...res.collections
                    ],
                    pagination: res.pagination
                };
        })
            .finally(() => this.loading = false);
    }
    getPopularCollections() {
        return this.popularCollections;
    }
    assetsProposals;
    async loadTakeAssetsProposals() {
        this.loading = true;
        const page = this.assetsProposals != undefined ? this.assetsProposals.pagination.next_page : 0;
        if (this.assetsProposals) {
            if (this.assetsProposals.pagination.next_page == null)
                return;
        }
        await getTakeOurProposals(page).then(async (res) => {
            if (page == 0)
                this.assetsProposals = res;
            if (page != 0 && this.assetsProposals && res.assets.length > 0)
                this.assetsProposals = {
                    assets: [
                        ...this.assetsProposals.assets,
                        ...res.assets
                    ],
                    pagination: res.pagination
                };
            //this.paginationAssets= res.pagination
        })
            .finally(() => this.loading = false);
    }
    getAssetsTakeProposals() {
        return this.assetsProposals;
    }
    getAuthor() {
        return this.author;
    }
    getAuthors() {
        return this.authors;
    }
    getAsset() {
        return this.asset;
    }
    getAssets() {
        return this.assets;
    }
    async buyAsset() {
        //if(!this.loading) return;
        if (!this.asset)
            return;
        if (this.web3ModalRef == null) {
            await this.logIn();
            //return;
        }
        this.loading = true;
        if (!this.asset.isApproved) {
            await this.approve().finally(() => this.loading = false);
            return;
        }
        const signer = await this.getSignOrProvider(true);
        if (signer == undefined) {
            return;
        }
        const marketContractSigner = new ethers.Contract(MARKET_ADDRESS_CONTRACT, abiMarket, signer);
        /*marketContractSigner.on( "ItemFinalizeBuy" , (tokenId, _tokenPrices, eventInfo)=>{
            console.log(tokenId, _tokenPrices,eventInfo)
            if(this.asset)
                this.asset.owner=this.user;
            alert("Congratulazione, l'acquisto stato finalizato!")
            
        })*/
        const decimals = await this.decimals();
        const auctionPrice = ethers.utils.parseUnits(this.asset?.price_commission.toString(), decimals);
        if (!this.asset.metadata) {
            alert("Token ID invalido!");
            return;
        }
        const tokenId = Number.parseInt(this.asset.metadata.mint);
        console.log(auctionPrice, auctionPrice.toBigInt());
        const txMintNft = await marketContractSigner.createOrderBuy(tokenId, auctionPrice)
            .then((res) => res)
            .catch((e) => {
            if (e.data) {
                alert(e.data.message);
            }
            if (e.message) {
                alert(e.message);
            }
            this.loading = false;
        });
        if (!txMintNft)
            return;
        this.message += `\n sending transaction hash: ${txMintNft.hash}`;
        await txMintNft.wait().then((res) => {
            //console.log(tokenId, _tokenPrices,eventInfo)
            if (this.asset)
                this.asset.owner = this.user;
            alert("Congratulazione, l'acquisto stato finalizato!");
        }).finally(() => this.loading = false);
        //}
    }
    clearWeb3Modal() {
        this.provider = null;
        this.web3ModalRef = undefined;
    }
    aboutInfo = [];
    async requestAboutInfo() {
        await requestAboutInfo().then(res => {
            this.aboutInfo = res.value; //.split('\n').filter(item=>item!="\r");
            this.termofuse = res.terms;
        })
            .catch(e => {
            console.log(e);
        })
            .finally(() => {
        });
    }
    getAboutInfo() {
        return this.aboutInfo;
    }
    termofuse = '';
    getTermsOfUse() {
        return this.termofuse;
    }
    getSymbolPrice() {
        return "BUSD";
    }
    stateMessage;
    async sendMessage(message) {
        this.stateMessage = message;
        this.loading = true;
        await sendContactMessage(message).then((res) => {
            if (res.id) {
                this.stateMessage = undefined;
                this.message = res.message;
            }
        }).finally(() => this.loading = false);
    }
    getStateMessage() {
        return this.stateMessage ?? {
            nome: "",
            email: "",
            subject: "",
            message: ""
        };
    }
    async requestRandomAsset() {
        await getRandomAssets().then((res) => {
            this.caruselAssets = res;
        });
    }
    getCaruselAsset() {
        return this.caruselAssets;
    }
}
export const STATE_APP_KEY = Symbol('IStateApp');
export function useStateApp() {
    const existStateMint = injectStrictWithSelf(STATE_APP_KEY);
    //const stateContract= useStateContract();
    if (existStateMint) {
        return existStateMint;
    }
    onMounted(() => {
        //if(existStateMint)
        if (window.ethereum != undefined) {
            window.ethereum.on('accountsChanged', (e) => actionState.handlerNewAccount(e));
            window.ethereum.on('connect', (connectInfo) => {
                console.log(connectInfo);
            });
        }
        else {
            alert("Warning: Metamask plugin not found on this browser. You see this message since you're trying to buy an NFT by a mobile device. The Metamask plugin is not the Metamask APP, so this message appears even you have installed the Metamask APP");
        }
        actionState.requestAboutInfo();
        actionState.requestRandomAsset();
        //actionState.loadCollection();
        //stateContract.start({address:NFT_ADDRESS_CONTRACT})
    });
    const providerOptions = {
        // Example with injected providers
        injected: {
            display: {
                //logo: "data:image/gif;base64,INSERT_BASE64_STRING",
                name: "Injected",
                description: "Connect with the provider in your Browser"
            },
            package: null
        },
        // Example with WalletConnect provider
        portis: {
            package: Portis,
            options: {
                id: "PORTIS_ID" // required
            }
        }
    };
    /*const web3ModalRef= new Web3Modal({
          network: "mumbai",
          providerOptions:  providerOptions,
          theme: {
              background: "rgb(39, 49, 56)",
              main: "rgb(199, 199, 199)",
              secondary: "rgb(136, 136, 136)",
              border: "rgba(195, 195, 195, 0.14)",
              hover: "rgb(16, 26, 32)"
          }

      });*/
    const stateApp = new StateApp();
    //stateApp.initWeb3Modal(web3ModalRef);
    const actionState = reactive(stateApp);
    provide(STATE_APP_KEY, actionState);
    return actionState;
}
