import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Explorer from '../views/Explorer.vue';
//import Asset from '../views/Asset.vue';
async function loadData(to, from, next) {
    //const stateApp= useStateApp();
    if (to.name == 'Authors') {
        //debugger
        //await stateApp.loadAuthors();
    }
    if (to.name == 'Collections') {
        //stateApp.loadCollections();
    }
    next();
}
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/explorer',
        name: 'Explorer',
        component: Explorer
    },
    {
        path: '/collections',
        name: 'Collections',
        component: () => import('../views/Collections.vue')
    },
    {
        path: '/authors',
        name: 'Authors',
        component: () => import('../views/Authors.vue'),
        beforeEnter: [loadData],
    },
    {
        path: '/author/:alias',
        name: 'Author',
        component: () => import('../views/AuthorCollections.vue'),
    },
    {
        path: '/collection/:collectionId',
        name: 'ExplorerCollection',
        component: () => import('../views/ExplorerCollectionsV2.vue'),
    },
    {
        path: '/asset/:contract/:mint',
        name: 'Asset',
        component: () => import('../views/Asset.vue'),
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: () => import('../views/Contacts.vue')
    },
    {
        path: '/about',
        name: 'Abouts',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/About.vue')
    },
    {
        path: '/terms',
        name: 'Terms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Terms.vue')
    }
];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});
export default router;
