import { defineComponent } from 'vue';
import PagesHeader from "./../components/PagesHeader.vue";
import PagesTitle from "./../components/PagesTitle.vue";
export default defineComponent({
    components: {
        PagesHeader,
        PagesTitle
    },
    setup() {
    },
});
