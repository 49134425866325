import axios from "axios";
//import { User } from ".";
const HTTP_CONNECTION = '/';
export const getAuthors = async () => {
    const res = await axios.get(`${HTTP_CONNECTION}api/authors`);
    return res.data;
};
export const getAuthorCollectionsByAuthorID = async (authorId) => {
    const res = await axios.get(`${HTTP_CONNECTION}api/author/${authorId}`);
    return res.data;
};
export const getCollectionByID = async (id) => {
    const res = await axios.get(`${HTTP_CONNECTION}api/author/collection/${id}`);
    return res.data;
};
export const getAssetByID = async (contract, mint) => {
    const res = await axios.get(`${HTTP_CONNECTION}api/asset/${contract}/${mint}`);
    return res.data;
};
export const getNewAssets = async (page = 0) => {
    return await axios.get(`${HTTP_CONNECTION}api/asset/news?page=${page}`)
        .then(res => res.data);
};
/**
 *
 * @param page
 * @returns PaginationCollections
 *  *
 */
export const getPopularCollections = async (page = 0) => {
    return await axios.get(`${HTTP_CONNECTION}api/collections/popular?page=${page}`)
        .then(res => res.data);
};
/**
 *
 * @returns
 */
export const getTakeOurProposals = async (page = 0) => {
    return await axios.get(`${HTTP_CONNECTION}api/assets/proposals?page=${page}`)
        .then(res => res.data);
};
export const getCollections = async () => {
    const res = await axios.get(`${HTTP_CONNECTION}api/collections/all`);
    return res.data;
};
export const requestAboutInfo = async () => {
    const res = await axios.get(`${HTTP_CONNECTION}api/about`);
    debugger;
    return res.data;
};
export const sendContactMessage = async (message) => {
    return await axios.post(`${HTTP_CONNECTION}api/send/message`, { ...message }).then(res => res.data);
};
export const getRandomAssets = async () => {
    const res = await axios.get(`${HTTP_CONNECTION}api/random/assets`);
    return res.data;
};
/*
interface ArgsCreateMetadata{
    files_id: number,
    mint?: number,
    smartContract?: string,
    hash?: string
}

export const createMetadataRequest=async ( args: ArgsCreateMetadata)=>{
    const res = await axios.post<FileMetadata>(`${HTTP_CONNECTION}api/metadata`, {...args});
    return res.data;
}
*/
