import { computed, defineComponent } from "vue";
import { useStateApp } from "../providers/stateApp";
export default defineComponent({
    setup() {
        //const currentPage= ref<number>(0);
        const stateApp = useStateApp();
        stateApp.loadNewAssets();
        const latestWorks = computed(() => {
            const assets = stateApp.getLatestWorksAssets();
            return assets;
        });
        const loadLatestWorksAssets = async () => {
            stateApp.loadNewAssets();
        };
        //const pagination= computed(()=> stateApp.getPaginationLatestWorks());
        const isLoading = computed(() => stateApp.isLoading());
        const symbolPrice = computed(() => stateApp.getSymbolPrice());
        /*watchEffect(() => {
            if(assets.value.length>0){
                stateApp.getOwnerAssets();
            }
        })*/
        return {
            latestWorks,
            symbolPrice,
            isLoading,
            loadLatestWorksAssets,
            // pagination
        };
    },
});
