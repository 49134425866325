<template>
    <div class="card col-sm-4">
        <img class="card-img-top" src="https://edeline.ru/nft-demo/assets/img/cards/auction_1.jpg" alt="Card image cap">
        <div class="card-body">
            <div class="avatar-a"><img src="https://edeline.ru/nft-demo/assets/img/users/avatar_1.jpg" alt="Card image cap"></div>
            <div class="a-det">
                <a href="https://edeline.ru/nft-demo/author.html">
                    <h2>John Doe</h2>
                </a>
                <a href="#" class="button-vl">Follow </a>
            </div>
            <br>
            <br>
        </div>
    </div>
</template>