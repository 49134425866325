import { computed, defineComponent, onMounted } from 'vue';
import { Carousel } from 'bootstrap';
import { useStateApp } from '../providers/stateApp';
export default defineComponent({
    props: {
        title: String
    },
    setup() {
        const stateApp = useStateApp();
        const items = computed(() => stateApp.getCaruselAsset());
        const symbolPrice = computed(() => stateApp.getSymbolPrice());
        onMounted(() => {
            //var myCarousel = document.querySelector('#carouselExampleCaptions')
            debugger;
            const carousel = new Carousel("#carouselExampleCaptions");
            // carousel.pause();
            console.log(carousel);
        });
        return {
            items,
            symbolPrice
        };
    },
});
