import { defineComponent } from 'vue';
import HeroImage from "./../components/HeroImage.vue";
import HowWorks from "./../components/HowWorks.vue";
import LatestWorks from './../components/LatestWorks.vue';
import PopularCollections from './../components/PopularCollections.vue';
import TakeAssetsProposals from '../components/TakeAssetsProposals.vue';
export default defineComponent({
    name: 'Home',
    components: {
        HeroImage,
        HowWorks,
        LatestWorks,
        PopularCollections,
        TakeAssetsProposals
    },
});
