import { defineComponent, onMounted, ref } from 'vue';
import BoxAuthor from "./components/CardAuthor.vue";
import ButtonLogin from "./components/ButtonLogin.vue";
import PageFooter from "./components/PagesFooter.vue";
import { useStateApp } from './providers/stateApp';
//import  { Toast } from 'vue-dk-toast';
export default defineComponent({
    components: {
        BoxAuthor,
        ButtonLogin,
        PageFooter
    },
    setup({}) {
        const show = ref(false);
        const stateApp = useStateApp();
        onMounted(() => {
            /*if(window.ethereum != undefined ){
                window.ethereum.on('accountsChanged',(e:any) =>stateApp.handlerNewAccount(e));
                window.ethereum.on('connect',  (connectInfo:any) => {
                    console.log(connectInfo)
                });
            }else{
                alert("install metamask")
            }*/
        });
        return {
            show
        };
    },
});
