<template>
    <div class="container">
        <div class="row">
            <div class="sec-t">
                <!--
                <b> <span class="text-white">- Take a look to our proposals</span></b>
                -->
                <!--
                <h2>Create and sell your NFTs</h2>
                -->
            </div>
        </div>
    </div>
    <!--
    <div class="container">
        <div class="row">
            <div class="col-sm-3 section-down">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title"><i class="fas fa-wallet" aria-hidden="true"></i><br>Set up your wallet</h5>
                        <p class="card-text">Once you’ve set up your wallet of choice, connect it to OpenSea by clicking the NFT Marketplace in the top right corner. Learn about the wallets we support.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 section-down">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title"><i class="fas fa-th-large" aria-hidden="true"></i><br>Create your collection</h5>
                        <p class="card-text">Click Create and set up your collection. Add social links, a description, profile &amp; banner images, and set a secondary sales fee.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 section-down">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title"><i class="fas fa-cloud-upload-alt" aria-hidden="true"></i><br>Add your NFTs</h5>
                        <p class="card-text">Upload your work (image, video, audio, or 3D art), add a title and description, and customize your NFTs with properties, stats, and unlockable content.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 section-down">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title"><i class="fas fa-shopping-bag" aria-hidden="true"></i><br>List them for sale</h5>
                        <p class="card-text">Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    -->
</template>