import { computed, defineComponent } from "vue";
import { useStateApp } from "../providers/stateApp";
export default defineComponent({
    setup() {
        const stateApp = useStateApp();
        stateApp.loadPopularCollections();
        const popularCollection = computed(() => {
            const _collections = stateApp.getPopularCollections();
            return _collections;
        });
        const loadPopuparCollections = () => {
            stateApp.loadPopularCollections();
        };
        const isLoading = computed(() => stateApp.isLoading());
        return {
            loadPopuparCollections,
            popularCollection,
            isLoading,
        };
    },
});
