import { computed, defineComponent, ref } from 'vue';
import { useStateApp } from '../providers/stateApp';
export default defineComponent({
    setup() {
        const stateApp = useStateApp();
        const user = computed(() => stateApp.getUser());
        const showMenu = ref(false);
        const menuref = ref();
        const onLogin = async () => stateApp.logIn();
        //const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        //console.log(accounts,isInstallet)
        const setShowMenu = (sw) => {
            showMenu.value = sw;
            //menuref.value.
            // debugger
            menuref.value?.focus();
            //console.log(menuref.value);
        };
        const logout = () => {
            stateApp.logout();
            setShowMenu(false);
        };
        /*
        onMounted(()=>{
            
        })
        */
        const truncate = (text, length, suffix) => {
            if (text.length > length) {
                return text.substring(0, length - length + 5) + suffix + text.substring(text.length - 5, text.length);
            }
            else {
                return text;
            }
        };
        return {
            user,
            onLogin,
            showMenu,
            setShowMenu,
            logout,
            menuref,
            truncate
        };
    },
});
