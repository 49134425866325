import { computed, defineComponent } from "vue";
import { useStateApp } from "../providers/stateApp";
export default defineComponent({
    setup() {
        const stateApp = useStateApp();
        stateApp.loadTakeAssetsProposals();
        const takeProposalsAssets = computed(() => {
            const assets = stateApp.getAssetsTakeProposals();
            return assets;
        });
        const loadTakeAssetsProposals = () => stateApp.loadTakeAssetsProposals();
        const symbolPrice = computed(() => stateApp.getSymbolPrice());
        const isLoading = computed(() => stateApp.isLoading());
        return {
            takeProposalsAssets,
            symbolPrice,
            isLoading,
            loadTakeAssetsProposals
        };
    },
});
