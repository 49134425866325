//import { createClient, useQuery } from 'urql'
import { createClient } from "@urql/vue";
const APIURL = 'https://api.thegraph.com/subgraphs/name/arawipacha/intomyworld';
const existNftbyOwner = `
query($owner: String!, $tokenId: ID!){
    artes(where:{
      owner:$owner,
      id: $tokenId
    }) {
      id
      transactionHash
      owner {
        id
      }
      author {
        id
      }
    }
  }
`;
const client = createClient({
    url: APIURL,
});
export const getNftByOwnerMarket = async (owner, tokenId) => {
    /*const { fetching, data, error } = await apiquery({
        query: existNftbyOwner,
        variables: { owner, tokenId }
    });


    if (fetching) {
        console.log(`Loading ${owner}...`);
    } else if (error) {
        console.log(`Oh no! Error: ${error}`);
        return ;
    }
    
    const asset = data.value;
    return asset*/
    //return await client.query(existNftbyOwner, variables:{owner, id}).toPromise()
    return await client.query(existNftbyOwner, { owner, tokenId }).toPromise().then(res => res.data);
};
const queryOwnerAssets = `
query($owner: String!, $assetsId: [ID]!){
    artes(where:{
      owner:$owner,
      id_in: $assetsId
    }) {
      id
      transactionHash
      owner {
        id
      }
      author {
        id
      }
    }
  }
`;
export const getAssetsByOwnerMinter = async (owner, assetsId) => {
    /* const { fetching, data, error } = await apiquery({
         query: queryOwnerAssets,
         variables: { owner, assetsId }
     });*
   
   
     if (fetching) {
         console.log(`Loading ${owner}...`);
     } else if (error) {
         console.log(`Oh no! Error: ${error}`);
         return ;
     }
     
     const asset = data.value;*/
    //return asset
    return await client.query(queryOwnerAssets, { owner, assetsId }).toPromise().then(res => res.data);
};
